<template>
  <div class="BottomContainer">
    <el-footer>
     
     by 微甜人生
     
      <el-link type="info" href="https://beian.miit.gov.cn/">蜀ICP备18038087号-6</el-link>
     </el-footer>
  </div>
</template>
<script>
export default {
  name: "BottomContainer",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.el-footer {
  background-color: #CCCCCC;
  color: #333;
  text-align: center;
  line-height: 60px;
}
</style>
